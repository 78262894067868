import * as schemaItemTypes from './schemaItemTypes';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.CREDENTIALS_VARIANT,
        label: 'employee.credentials',
        prop: 'accessLevels',
        size: 6,
      },
      {
        type: schemaItemTypes.ROLE_EMPLOYEES,
        label: 'role.users',
        prop: 'employees',
        size: 6,
      },
    ],
  },
];

export { schema as default };
